
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import BlogPage from "./page/blog";
import Myhome from "./page/Myhome";
import Lifeinsurance from "./page/lifeinsurance";
import TermLifeInsurance from "./page/Term-Life-Insurance";
import WholeLifeInsurance from "./page/Whole-Life-Insurance";
import UniversalLifeInsurance from "./page/Universal-Life-Insurance";
import IndexedUniversalLifeInsurance from "./page/Indexed-Universal-Life-Insurance";
import Financialplanning from "./page/financialplanning";
import WealthManagement from "./page/Wealth-Management";
import RiskManagement from "./page/Risk-Management";
import EstateManagement from "./page/Estate-Management";
import InvestmentManagement  from "./page/Investment-Management";
import AssetProtection from "./page/Asset-Protection";
import TaxManagement from "./page/Tax-Management";
import BusinessTransitionPlanning from "./page/Business-Transition-Planning";
import Faq from "./page/faq";
import Contactus from "./page/contact-us";
import Thankyou from "./page/thankyou";
import TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture from "./page/Allblogs/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture.jsx";
import UnlockingFinancialFreedomThePowerofFinancialPlanning from "./page/Allblogs/UnlockingFinancialFreedomThePowerofFinancialPlanning";
import KeyTermsWhenComparingTermLifeInsurancePlans from "./page/Allblogs/KeyTermsWhenComparingTermLifeInsurancePlans";
import WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner from "./page/Allblogs/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner";
import RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner from "./page/Allblogs/RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner";
import InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner from "./page/Allblogs/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner";
import SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection from "./page/Allblogs/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection";
import TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner from "./page/Allblogs/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner";
import BusinessTransitionPlanningAStrategicGuideforLongTermSuccess from "./page/Allblogs/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess";
import UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture from "./page/Allblogs/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture";
import UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner from "./page/Allblogs/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner";
import UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility from "./page/Allblogs/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility";
import Insurancead from "./page/Insurance-ad";
import Financialallleadform from "./page/financial-allleadform";

function App() {
	return (
		<BrowserRouter >
			<ScrollToTop />
			<Routes>
			<Route path="/financial-allleadform" element={<Financialallleadform />} />
			<Route path="/Insurance-ad" element={<Insurancead />} />
			<Route path="/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility" element={<UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility />} />
			<Route path="/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner" element={<UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner />} />
			<Route path="/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture" element={<UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture />} />
			<Route path="/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess" element={<BusinessTransitionPlanningAStrategicGuideforLongTermSuccess />} />
			<Route path="/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner" element={<TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner />} />
			<Route path="/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection" element={<SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection />} />
			<Route path="/" element={<Myhome />} />
			<Route path="/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner" element={<InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner />} />
			<Route path="/RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner" element={<RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner />} />
			<Route path="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner" element={<WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner/>} />
			<Route path="/UnlockingFinancialFreedomThePowerofFinancialPlanning" element={<UnlockingFinancialFreedomThePowerofFinancialPlanning />} />
			<Route path="/KeyTermsWhenComparingTermLifeInsurancePlans" element={<KeyTermsWhenComparingTermLifeInsurancePlans />} />
			<Route path="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture" element={<TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture />} />
			
			<Route path="/thankyou" element={<Thankyou />} />
			<Route path="/faq" element={<Faq />} />
			<Route path="/contact-us" element={<Contactus />} />
			<Route path="/Business-Transition-Planning" element={<BusinessTransitionPlanning />} />
			<Route path="/Tax-Management" element={<TaxManagement />} />
			<Route path="/Asset-Protection" element={<AssetProtection />} />
			<Route path="/Investment-Management" element={<InvestmentManagement />} />
			<Route path="/Estate-Management" element={<EstateManagement />} />
			<Route path="/Risk-Management" element={<RiskManagement />} />
			<Route path="/Wealth-Management" element={<WealthManagement />} />
			<Route path="/financialplanning" element={<Financialplanning />} />
			<Route path="/Indexed-Universal-Life-Insurance" element={<IndexedUniversalLifeInsurance />} />
			<Route path="/Universal-Life-Insurance" element={<UniversalLifeInsurance />} />
			<Route path="/Whole-Life-Insurance" element={<WholeLifeInsurance />} />
			<Route path="/Term-Life-Insurance" element={<TermLifeInsurance />} />
			<Route path="/Lifeinsurance" element={<Lifeinsurance />} />
			<Route path="blog" element={<BlogPage />} />
			<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
