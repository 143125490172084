
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { format } from 'date-fns';
import { addDays } from 'date-fns';
import React, { useState, useEffect , useRef } from "react";
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import loaderImage from '../../../src/assets/loader/loader.gif'; // Import the loader image
import { InlineWidget } from 'react-calendly'; // Add this line to import Calendly
import TimePicker from 'react-time-picker'; // Add this line to import TimePicker


function TimePickerDropdown({ value, onChange }) {
  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  const handleHourChange = (e) => {
    const newHour = parseInt(e.target.value, 10);
    onChange(`${newHour}:${value.split(':')[1]}`);
  };

  const handleMinuteChange = (e) => {
    const newMinute = parseInt(e.target.value, 10);
    onChange(`${value.split(':')[0]}:${newMinute}`);
  };

  return (
    <div>
      <select className="timerpicker" value={parseInt(value.split(':')[0], 10)} onChange={handleHourChange}>
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <span> : </span>
      <select className="timerpicker" value={parseInt(value.split(':')[1], 10)} onChange={handleMinuteChange}>
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute < 10 ? `0${minute}` : minute}
          </option>
        ))}
      </select>
    </div>
  );
}


function Mynewform() {
  const [step, setStep] = useState(1); // Tracks the current step
  const [gender, setGender] = useState("");
  const [existingPolicy, setExistingPolicy] = useState("");
  const [medicalConditions, setMedicalConditions] = useState("");
  const [smoker, setSmoker] = useState("");
  const [hazardousActivities, setHazardousActivities] = useState("");
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [ampm, setampm] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedTime, setSelectedTime] = useState('12:00');
  const [selectedDate, setSelectedDate] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isFormReady, setIsFormReady] = useState(false);


  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  // Function to get the current date
  const getCurrentDate = () => {
    return new Date();
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  // const handleScheduleEventClick = () => {
  //   // Redirect to the thank-you page when the button is clicked
  //   window.location.href = 'https://mypolicyplanner.com/thankyou';
  // };

  // Function to check if the form is ready to be submitted
  const checkFormValidity = () => {
    // Add your validation logic here
    const isStep1Valid = gender && existingPolicy && medicalConditions;
    const isStep2Valid = smoker && hazardousActivities;
    const isStep3Valid = name && occupation && annualIncome && city && email && phoneNumber &&selectedDate1 &&selectedDate && selectedTime && ampm;
  
    return isStep1Valid && isStep2Valid && isStep3Valid;
  };

  // Update isFormReady whenever the fields are updated
  useEffect(() => {
    setIsFormReady(checkFormValidity());
  }, [step, gender, existingPolicy, medicalConditions, smoker, hazardousActivities, name, occupation, annualIncome, city, email, phoneNumber, selectedDate1 , selectedTime, ampm, selectedDate]);
  
  
  
  const handleNext = () => {
    if (step === 1) {
      const isStep1Valid = gender && existingPolicy && medicalConditions;
      if (!isStep1Valid) {
        // Alert the user that they need to select valid options
        alert("Please select valid options before proceeding.");
        return; // Exit the function and don't proceed to the next step
      }
    } else if (step === 2) {
      const isStep2Valid = smoker && hazardousActivities;
      if (!isStep2Valid) {
        // Alert the user that they need to select valid options
        alert("Please select valid options before proceeding.");
        return; // Exit the function and don't proceed to the next step
      }
    }
    else if (step === 4) {
      const isStep3Valid = name && occupation && annualIncome && city && email && phoneNumber && selectedDate1 &&selectedDate && selectedTime && ampm ;
      if (!isStep3Valid) {
        // Alert the user that they need to select valid options
        alert("Please select valid options before proceeding.");
        return; // Exit the function and don't proceed to the next step
      }
    }
  
    // If all validations pass, proceed to the next step
    setStep(step + 1);
  };
  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const form = useRef();
  // Construct the request body for the emailjs https://www.emailjs.com/
  const handleSubmit = async (e) => {
    e.preventDefault();

      if (!recaptchaValue) {
        alert("Please select reCAPTCHA before submitting the form.");
        return;
      }
      // Check if the email contains "@mailinator.com"
      if (email.includes("@mailinator.com")) {
        alert("Emails from mailinator.com are not allowed.");
        return; // Exit the function and don't proceed to form submission
      }
      // Ensure that selectedTime is not empty
      if (!selectedTime) {
        alert("Please select a time for the consultation.");
        return;
      }
      setIsLoading(true); // Set loading state to true when submitting

        emailjs.sendForm('service_0ql8mq8', 'template_8ji9ucs', form.current, 'o82aGIEvWnfIcm6WE')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        
  
    // Construct the request body based on the step
    const requestBody = {
      data: {
        gender: gender,
        existingpolicy: existingPolicy,
        medicalconditions: medicalConditions,
        smoker:smoker,
        hazardousactivities: hazardousActivities,
        name:name,
        occupation:occupation,
        annualincome: annualIncome,
        ampm:ampm,
        city:city,
        email:email,
        phonenumber: phoneNumber,
        selectedtime: selectedTime ,
        selecteddate1: selectedDate1 ? format(selectedDate1, 'yyyy-MM-dd') : "",
        selecteddate: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : "",
      },
    };
  
    try {
      // Send data to Google Sheet API
      const response = await fetch("https://api.apispreadsheets.com/data/yXoc3iJMQwYuE0v5/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      window.location.href = 'https://mypolicyplanner.com/thankyou';

      // Send data to email script
      const emailResponse = await fetch("lifeinsurancelead.php", {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(requestBody.data).toString(),
      });
      // Check if the response is successful
      if (response.ok && emailResponse.ok) {
        // Set the step to 4 after successful submission
        setStep(4);
      } else {
        console.error("An error occurred:", response.statusText);
      }


      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setTimeout(() => setResponseStatus(null), 2000);
      setIsLoading(false); // Reset loading state in case of an error
    }
  };

  return (
    <div>
      <form encType="multipart/form-data"
                      id="jobdetil"
                      ref={form} onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
        {/* Radio buttons for gender */}
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
          <p className='Gener'>Select Your Gender</p>
          <div className='radiogroup'>
            <label>
              Male
              <input type="radio" id="male" name="gender" value="male" checked={gender === "male"} onChange={(e) => setGender(e.target.value)}  />
            </label>
            <label>
              Female
              <input type="radio"id="female" name="gender" value="female" checked={gender === "female"}onChange={(e) => setGender(e.target.value)}  />
            </label>
          </div>
          </div>
          </div>
          {/* Dropdown for existingpolicy */}
          <label>Do you have any existing life insurance policies?</label>
          <select name="existingpolicy" id="existingpolicy" value={existingPolicy} onChange={(e) => setExistingPolicy(e.target.value)} required  >
            <option value="">Select an option</option>
            <option value="yes" name="yes">Yes</option>
            <option value="no" name="no">No</option>
          </select>
          {/* Dropdown for medicalconditions */}
          <label>Do you have any pre-existing medical conditions?</label>
          <select name="medicalconditions" id="medicalconditions" value={medicalConditions} onChange={(e) => setMedicalConditions(e.target.value)} required  >
            <option value="">Select an option</option>
            <option value="yes" name="yes" >Yes</option>
            <option value="no" name="no">No</option>
          </select>
            <button type="button" className="next" onClick={handleNext}>Next</button>
          </div>
        ) }
        {step === 2 && (
          <div>
            {/* Smoker Dropdown */}
            <label>Are you a smoker?</label>
          <select name="smoker" id="smoker" value={smoker} onChange={(e) => setSmoker(e.target.value)} required  >
            <option value="">Select an option</option>
            <option value="yes" name="yes">Yes</option>
            <option value="no" name="no">No</option>
          </select>

          {/* Hazardous Activities Dropdown */}
          <label>Do you engage in any hazardous activities or extreme sports?</label>
          <select name="hazardousactivities" id="hazardousactivities" value={hazardousActivities} onChange={(e) => setHazardousActivities(e.target.value)} required  >
            <option value="">Select an option</option>
            <option value="yes" name="yes" >Yes</option>
            <option value="no" name="no">No</option>
          </select>

          <ul className="formlisted">
            <li>   <button className="previous" onClick={handlePrevious}> Previous </button></li>
            <li> <button className="next" onClick={handleNext}> Next </button></li></ul>
          </div>
        )}
        {step === 3 && (
          <div>
            {/* Remaining Fields */}
            <div className="row">
                <label className="cdtfc">Choose Date & Time for 30 Minute Free Cosultation</label>
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                  <DatePicker name="date" className="reg-form1" selected={selectedDate1} onChange={(date) => setSelectedDate1(date)} placeholderText="Date for Cosultation" required minDate={getCurrentDate()} />
                </div>
                <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 col-8" id='formdiv'>
                <TimePickerDropdown value={selectedTime} onChange={handleTimeChange} />
                </div>
                <div className="col-xxl-2 col-xl-2 col-md-2 col-lg-2 col-4" id='formdiv'>
                    <select name="ampm" className="timerpicker1" value={ampm} onChange={(e) => setampm(e.target.value)} required  >
                      <option value="">AM/PM</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                </div>
            </div>   
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                  <input type="text" name="name" className="reg-form" placeholder="Your Name" value={name}onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv' >
                <input type="text" name="occupation" className="reg-form" placeholder="Your Occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)} required />
                </div>
            </div>  
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                  <select name="annualincome" className="reg-form" value={annualIncome} onChange={(e) => setAnnualIncome(e.target.value)} required  >
                    <option value="">Annual Income</option>
                    <option value="Less50,000">Less than $50,000</option>
                    <option value="50,001-150,000">$50,001 - $150,000</option>
                    <option value="150,001-300,000">$150,001 - $300,000</option>
                    <option value="More than - 300,001">More than $300,001</option>
                  </select>
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv' >
                <input type="text" name="city" className="reg-form" placeholder="Your City" value={city} onChange={(e) => setCity(e.target.value)} required />
                </div>
            </div> 
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                <DatePicker name="date" className="reg-form" selected={selectedDate} onChange={(date) => setSelectedDate(date)} placeholderText="Date of Birth" required />
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv' >
                <input type="tel" name="phonenumber" className="reg-form" placeholder="Your Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required/>
                </div>
            </div>   
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                  <input type="email" name="email" className="reg-form" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                </div>
            </div>  
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                <ReCAPTCHA
                  sitekey="6LfnbusnAAAAAMVoRLl7dNqnOl5aV27PvqTX-Lmm"
                   onChange={handleRecaptchaChange}
                />
                </div>
            </div> 
            <ul className="formlisted">
              <li> <button className="previous" onClick={handlePrevious}> Previous </button></li>
              {/* <li> <input type="submit" className='submit' value="Submit" /> </li> */}
              {/* <input type="submit" className='submit' value="Submit" disabled={recaptchaValue === null} /> */}
              <li>
                {/* Conditionally render loader or submit button */}
                {isLoading ? (
                  <div className="loader">
                    <img src={loaderImage} alt="Loading" />
                  </div>
                ) : (
                  <input type="submit" className="submit" value="Submit"/>
                )}
              </li>
            </ul>
          </div>
        )}
        {step === 4 && (
          <div>
              {/* Remaining Fields */}
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                  {/* Add the Calendly widget here */}
                  <InlineWidget
                    // onClick={handleScheduleEventClick} // Add the onClick event handler here
                  />
                </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default Mynewform;
