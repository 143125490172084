import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import phonecall from "../../assets/images/insurance/phonecall.png";

const HeaderSix = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);
	const [searchTrigger, setSearchTrigger] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    return (
        <Fragment>
            <header className={`header-section style-4 style-6 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
               
                <div className="header-bottom">
                    <div className="container">
                                          <div className="header-wrapper">
                            <div className="logo-search-acte">
                                <div className="logo">
                                    <Link to="/"><img src="assets/images/logo/logo.png" alt="logo" className="sitelogo" /></Link>
                                </div>
                            </div>
                            <div className="menu-area">
                                <div className="menu">
                                    <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/lifeinsurance">life Insurance</NavLink></li>
                                    <li><NavLink to="/financialplanning">Financial Planning </NavLink></li>
                                     {/* <li className="menu-item-has-children">
                                     <a href="/lifeinsurance" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">life Insurance</a>
                                     <ul className="lab-ul dropdown-menu">
                                      <li><NavLink to="/lifeinsurance">life Insurance</NavLink></li>
                                      <li><NavLink to="/Term-Life-Insurance">Term Life Insurance</NavLink></li>
                                      <li><NavLink to="/Whole-Life-Insurance">Whole Life Insurance</NavLink></li>
                                      <li><NavLink to="/Universal-Life-Insurance">Universal Life Insurance</NavLink></li>
                                     <li><NavLink to="/Indexed-Universal-Life-Insurance">Indexed Universal Life Insurance </NavLink></li> 
                                     </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="/financialplanning" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Financial Planning</a>
                                            <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/financialplanning">Financial Planning </NavLink></li>
                                                <li><NavLink to="/Wealth-Management">Wealth Management </NavLink></li>
                                                <li><NavLink to="/Risk-Management">Risk Management</NavLink></li>
                                                <li><NavLink to="/Estate-Management">Estate Management</NavLink></li>
                                                <li><NavLink to="/Investment-Management">Investment Management </NavLink></li>
                                                <li><NavLink to="/Asset-Protection">Asset Protection</NavLink></li>
                                                <li><NavLink to="/Tax-Management">Tax Management</NavLink></li>
                                                <li><NavLink to="/Business-Transition-Planning">Business Transition Planning </NavLink></li>
                                            </ul>
                                        </li> */}
                                        <li><NavLink to="/faq">FAQ</NavLink></li>
                                    <li><NavLink to="/contact-us">Contact</NavLink></li>
                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                    <li>   <div className="headercnt">
								<div className='chatmsg'>
								<img src={phonecall}  alt='' className='chatmessage' />
							 </div>
							  <div className="box-body">
 							 <h3 className="nav-info-box-title">Toll Free Number </h3>
 							 <p><a href="tel:+1 844 203-4105">+1 844 203-4105</a></p>
                            	</div>
								</div></li>
                                    </ul>
                                 
                                </div>

                                  <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`menu-search-form ${searchTrigger ? "open" : ""}`}>
                <div className="search-close" onClick={() => setSearchTrigger(!searchTrigger)}></div>
                <form>
                    <input type="text" name="search" placeholder="Search here..." />
                    <button type="submit"><i className="icofont-search"></i></button>
                </form>
            </div>
        </Fragment>
    );
}
 
export default HeaderSix;